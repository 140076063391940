<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">灭火器每日检查</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
          <el-form-item label="日期" prop="date" size="large" label-width="160px">
            <el-date-picker v-model="pageForm.date"
                            type="date"
                            size="large"
                            placeholder="请选择日期"
                            value-format="YYYY-MM-DD"/>
          </el-form-item>
          <el-form-item label="实验室" prop="laboratoryId" size="large" label-width="160px">
            <el-select v-model="pageForm.laboratoryId"
                       filterable
                       class="room-select-tl"
                       placeholder="选择实验室"
                       size="large">
              <el-option v-for="item in laboratoryList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="灭火箱" prop="fireBox" size="large" label-width="160px">
            <el-input type="text"
                      v-model="pageForm.fireBox"
                      maxlength="5"
                      @blur="integerParse(pageForm.fireBox, val => pageForm.fireBox = val)"></el-input>
          </el-form-item>
          <el-form-item label="灭火器" prop="fireExtinguisher" size="large" label-width="160px">
            <el-input type="text"
                      maxlength="5"
                      v-model="pageForm.fireExtinguisher"
                      @blur="integerParse(pageForm.fireExtinguisher, val => pageForm.fireExtinguisher = val)"></el-input>
          </el-form-item>
          <el-form-item label="压力情况(绿色段)" prop="pressure" size="large" label-width="160px">
            <el-radio-group v-model="pageForm.pressure" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="外观完整性" prop="appearance" size="large" label-width="160px">
            <el-radio-group v-model="pageForm.appearance" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="喷嘴完整性" prop="spray" size="large" label-width="160px">
            <el-radio-group v-model="pageForm.spray" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="保险栓" prop="insure" size="large" label-width="160px">
            <el-radio-group v-model="pageForm.insure" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="时效性" prop="ageing" size="large" label-width="160px">
            <el-radio-group v-model="pageForm.ageing" class="ml-4">
              <el-radio label="1" size="large">合格</el-radio>
              <el-radio label="0" size="large">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="巡检员" prop="userId" size="large" label-width="160px">
            <el-select v-model="pageForm.userId"
                       filterable
                       class="room-select-tl"
                       placeholder="搜索巡检员"
                       size="large"
                       remote
                       :remote-method="getUserList"
                       @change="selectChange">
              <el-option v-for="item in teacherList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              />
              <template #prefix>
                <div>
                  {{ teacher.code }}
                </div>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="现场照片" prop="image" size="large" label-width="160px">
            <image-list ref="imageList"></image-list>
          </el-form-item>
          <el-form-item label="备注" prop="notes" size="large" label-width="160px">
            <el-input v-model="pageForm.notes"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
  </div>
</template>

<script>
import {getAllLaboratory, getTeacherList, setExtinguisher} from "@/api/examination";
import {integerParse} from "@/utils/common";
import ImageList from "@/view/patrolInspection/ImageList";

export default {
  name: "laboratoryEquipment",
  components: {ImageList},
  data(){
    return {
      teacherList: [],
      teacher: {},
      laboratoryList: [],
      pageForm: {
        // 日期
        date: '',
        // 实验室名称
        laboratoryId: '',

        // 灭火箱
        fireBox: '',
        // 灭火器
        fireExtinguisher: '',
        // 压力情况(绿色段)
        pressure: '1',
        // 外观完整性
        appearance: '1',
        // 喷嘴完整性
        spray:'1',
        // 保险栓
        insure: '1',
        // 时效性
        ageing: '1',

        // 巡检人员
        userId: '',
        // 备注
        notes: '',
      },
      pageFormRules: {
        date: [
          { required: true, message: '请选择日期', trigger: 'blur' },
        ],
        laboratoryId: [
          { required: true, message: '请选择实验室', trigger: 'blur' },
        ],
        userId: [
          { required: true, message: '请选择巡检员', trigger: 'blur' },
        ],

        // 灭火箱
        fireBox: [
          { required: true, message: '请填写灭火箱数量', trigger: 'blur' },
        ],
        // 灭火器
        fireExtinguisher: [
          { required: true, message: '请填写灭火器数量', trigger: 'blur' },
        ],
        // 压力情况(绿色段)
        pressure: [
          { required: true, message: '请检验 压力情况', trigger: 'blur' },
        ],
        // 外观完整性
        appearance: [
          { required: true, message: '请检验 外观完整性', trigger: 'blur' },
        ],
        // 喷嘴完整性
        spray:[
          { required: true, message: '请检验 喷嘴完整性', trigger: 'blur' },
        ],
        // 保险栓
        insure: [
          { required: true, message: '请检验 保险栓', trigger: 'blur' },
        ],
        // 时效性
        ageing: [
          { required: true, message: '请检验 时效性', trigger: 'blur' },
        ],
      },
      id: null,
    }
  },
  mounted() {
    this.init();
    let data=this.$root.useRoute.query;
    if(data.info){
      this.pageForm=JSON.parse(data.info);
      this.$refs.imageList.setImageInfo(this.pageForm.img);
      if(this.pageForm && this.pageForm.teacher) {
        this.getUserList(this.pageForm.teacher.name);
      }
    }
  },
  methods: {
    integerParse,
    selectChange(e){
      this.teacher = this.teacherList.find(iter => iter.id == e) || {}
    },
    init(){
      getAllLaboratory().then(res => {
        if (res.success) {
          this.laboratoryList = res.data
        } else {
          this.$root.ElMessage.error(res.message)
        }
      })
    },
    getUserList(keyword){
      if (keyword) {
        getTeacherList({ name: keyword }).then(res => {
          if (res.success) {
            this.teacherList = res.data
          } else {
            this.$root.ElMessage.error(res.message)
          }
        })
      }
    },
    getTeachers(){

    },
    subData(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          const handler = setExtinguisher
          let method='post';
          if(this.pageForm.id){method='put'}
          handler({ ...this.pageForm, img: this.$refs.imageList.getImg() },method).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              setTimeout(() => {
                this.$root.useRouter.replace('/patrolInspection/fireExtinguisherList')
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>